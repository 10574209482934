import './breadcrumb.scss';

class Breadcrumb {
    constructor (element) {
        this.$breadcrumbs = element;
    }

    setShadows () {
        const $breadcrumbList = this.$breadcrumbs.querySelector('.breadcrumb__list');

        $breadcrumbList.addEventListener('scroll', () => {
            const scrollLeft = $breadcrumbList.scrollLeft;
            const scrollWidth = $breadcrumbList.scrollWidth;
            const listWidth = $breadcrumbList.offsetWidth;
            const endScroll = scrollWidth - listWidth - 10;

            if (scrollLeft > 10) {
                this.$breadcrumbs.classList.add('show-left-shadow');
            } else {
                this.$breadcrumbs.classList.remove('show-left-shadow');
            }

            if (scrollLeft > endScroll) {
                this.$breadcrumbs.classList.add('hide-right-shadow');
            } else {
                this.$breadcrumbs.classList.remove('hide-right-shadow');
            }
        }, { passive: true });
    }
}

export { Breadcrumb };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $breadcrumb = $context.querySelector('[data-breadcrumb="root"]');
        if ($breadcrumb) {
            const breadcrumb = new Breadcrumb($breadcrumb);
            breadcrumb.setShadows();
        }
    }
});
